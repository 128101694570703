import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const About = () => {
    return (
        <Layout>
            <h1>About That</h1>
            <h2>I'm a builder of things. Sometimes with code, sometimes with wood.</h2>
            <h2>But always with love.</h2>
        </Layout>
    )
}

export default About
